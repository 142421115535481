import localForage from '@/lib/localForage';
import { getSession, signIn } from 'next-auth/client';
import { setGAUserId } from '@/lib/gtag';

const isBrowser = typeof window !== `undefined`;

export const setLocalSession = async user => {
  try {
    await localForage.setItem('localUser', user);
    setGAUserId(user && user.user ? user.user.userID : null);
  } catch (err) {
    console.log(err);
  }
};

export const updateSession = async prevSession => {
  if (typeof window === 'undefined') {
    return;
  }
  if (!prevSession) {
    throw new Error('must provide prev session');
  }
  await signIn(`update-user-session`, {
    redirect: false,
    username: prevSession.username,
    ...prevSession.user
  });
  const newSession = await getSession();
  await setLocalSession(newSession);
  return newSession;
};

export const clearUser = async () => {
  try {
    await localForage.removeItem('localUser');
  } catch (err) {
    console.log(err);
  }
};

const getUser = async () => {
  if (typeof window === 'undefined') {
    return;
  }
  try {
    const user = await localForage.getItem('localUser');
    return user || {};
  } catch (err) {
    console.log(err);
  }
  return {};
};

export const isLoggedIn = () => {
  if (!isBrowser) return false;

  const user = getUser();
  if (user) return !!user.username;
};

export const getCurrentUser = () => isBrowser && getUser();
export const clearStorage = () => isBrowser && clearUser();

export const localLogout = async () => {
  if (!isBrowser) return false;
  await setLocalSession({});
  await clearStorage();
  setGAUserId(null); // clear GA userId tracking

  return Promise.resolve();
};
