/* eslint-disable camelcase */
import { useRouter } from 'next/router';
import Link from 'next/link';
import dynamic from 'next/dynamic';
// import Image from 'next/image';
import { signOut } from 'next-auth/client';
import { Icon } from 'react-icons-kit';
import { ic_view_list } from 'react-icons-kit/md/ic_view_list';
import { ic_add } from 'react-icons-kit/md/ic_add';
import { userO } from 'react-icons-kit/fa/userO';
import { signOut as signOutIcon } from 'react-icons-kit/fa/signOut';
import { signIn as signInIcon } from 'react-icons-kit/fa/signIn';
import useIntlMessage from '@/hooks/use-intl-message';
import { localLogout } from '@/utils/auth';
import Avatar from '@/components/Layout/Avatar';
// import Tippy from '@/components/Tooltip';
const Tippy = dynamic(() => import('@/components/Tooltip'), { ssr: false });

const AccountAvatar = ({ session, user = {}, simple }) => {
  const router = useRouter();
  const { intlMessage: m } = useIntlMessage();
  // console.log({ session });
  const onLogOut = async () => {
    try {
      await localLogout(); // clear local storage data
      const outData = await signOut({ redirect: false, callbackUrl: '/' });
      // console.log({ outData });
      // router.replace('/');
      // router.push(outData.url);
      router.push('/');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  const MenuContent = (
    <div className="profile-dropdown">
      {session && (
        <div className="session-details">
          <div className="session-user">{user?.name || session.user.name}</div>
          <div className="session-email">{user?.username || session.username}</div>
        </div>
      )}
      {!simple && (
        <Link href="/create">
          <a>
            <button type="button" className="btn-primary">
              <Icon size={12} icon={ic_add} />{' '}
              {m({ id: 'new_poll', route: 'layout.navbar_account_avatar' })}
            </button>
          </a>
        </Link>
      )}

      {session ? (
        <>
          <Link href="/dashboard">
            <a>
              <button type="button">
                <Icon size={12} icon={ic_view_list} />{' '}
                {m({ id: 'dashboard', route: 'layout.navbar_account_avatar' })}
              </button>
            </a>
          </Link>
          <Link href="/account">
            <a>
              <button type="button">
                <Icon size={12} icon={userO} />{' '}
                {m({ id: 'account', route: 'layout.navbar_account_avatar' })}
              </button>
            </a>
          </Link>
          <button type="button" className="btn-logout" onClick={onLogOut}>
            <Icon size={12} icon={signOutIcon} />{' '}
            {m({ id: 'log_out', route: 'layout.navbar_account_avatar' })}
          </button>
        </>
      ) : (
        <>
          <Link href="/login">
            <a>
              <button type="button" className="btn-logout" onClick={() => {}}>
                <Icon size={12} icon={signInIcon} />{' '}
                {m({ id: 'log_in', route: 'layout.navbar_account_avatar' })}
              </button>
            </a>
          </Link>
        </>
      )}
    </div>
  );
  return (
    <Tippy
      theme="light"
      placement="bottom"
      trigger="click"
      interactive
      maxWidth={160}
      // offset={[0, 1]}
      // arrow={false}
      content={MenuContent}
    >
      <button type="button">
        <Avatar session={session} />
      </button>
    </Tippy>
  );
};

export default AccountAvatar;
