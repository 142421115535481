/* eslint-disable camelcase */
import { Icon } from 'react-icons-kit';
import { ic_person_outline } from 'react-icons-kit/md/ic_person_outline';
import { AvatarLetter } from '@/styles/index';

const Avatar = ({ children, width = 35, height = 35, session, ...restProps }) => {
  if (!session) {
    return (
      <AvatarLetter {...restProps}>
        <Icon icon={ic_person_outline} />
        {children}
      </AvatarLetter>
    );
  }

  const nameInitials = () => {
    const initials = session.user.name.split(' ').reduce((acc, curr) => {
      const initial = curr.charAt(0).toUpperCase();
      return `${acc}${initial}`;
    }, '');
    return initials;
  };
  const usernameInitial = session.username.charAt(0).toUpperCase();
  const avatarText = session.user.name ? nameInitials() : usernameInitial;

  if (session.user.image) {
    return (
      <img
        src={session.user.image}
        alt="user pic"
        className="user-pic"
        width={width}
        height={height}
      />
    );
  }
  return (
    <AvatarLetter width={width} height={height} {...restProps}>
      {avatarText}
      {children}
    </AvatarLetter>
  );
};

export default Avatar;
